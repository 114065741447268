import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  CustomInput,
} from "reactstrap";
import { jobInvoicesApi } from "../../../../services/jobInvoicesServices";
import NumericFormat from "../../../NumericFormat";

import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import CurrencyInput from "../../../CurrencyInput";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { utils } from "../../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faPlus,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { awsApi } from "../../../../services/awsServices";

const SENT_DATE = "SENT_DATE";
const PAID_DATE = "PAID_DATE";

const JobInvoiceModal = ({ defaultItem, onClose, onSubmit, terms }) => {
  const [loading, setLoading] = useState();
  const [attachments, setAttachments] = useState([]);

  const [jobInvoice, setJobInvoice] = useState({
    ...defaultItem,
    sentDate: defaultItem?.sentDate
      ? moment(defaultItem?.sentDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    paidDate: defaultItem?.paidDate
      ? moment(defaultItem?.paidDate).format("YYYY-MM-DD")
      : null,
  });

  const [dateSelectorOpen, setDateSelectorOpen] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let attachmentURLs = [];
      if (attachments && attachments.length) {
        attachmentURLs = await uploadAttachments();
      }
      await jobInvoicesApi.updateJobInvoice({
        ...jobInvoice,
        invoicePDF: attachmentURLs.length
          ? attachmentURLs[0].url
          : jobInvoice.invoicePDF || null,
      });
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Updated Job Invoice",
        body: "Job Invoice updated successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false });
          onSubmit();
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Create Job Invoice",
        body: "There was an error with your request.",
      });
    }
  };

  const uploadAttachments = async () => {
    try {
      const filesMapping = {};
      const attachmentsArray = attachments.map((file) => {
        filesMapping[file.name] = file;
        return {
          fileName: file.name,
        };
      });
      const attachmentUrls = await jobInvoicesApi.uploadAttachments({
        attachments: attachmentsArray,
      });
      for (let index = 0; index < attachmentUrls.length; index++) {
        const dataToUpload = {
          fileUrl: attachmentUrls[index].url,
          url: attachmentUrls[index].signedRequest,
          file: (attachmentUrls[index].file =
            filesMapping[attachmentUrls[index].fileName]),
        };
        attachmentUrls[index].file =
          filesMapping[attachmentUrls[index].fileName];
        await awsApi.putDocumentsToS3(dataToUpload);
      }
      return attachmentUrls;
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error to upload attachements.",
        onClose: () => {
          setInformationModal(utils.initInformationModal);
        },
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Edit Job Invoice
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="col">
                <Row className="col-12 mb-3">
                  <Col row={6}>
                    <Label>
                      Invoice#
                      <small className="text-danger ml-1">*</small>
                    </Label>
                    <Input
                      placeholder="Enter invoice #.."
                      required={true}
                      value={jobInvoice?.invoiceNumber || ""}
                      onChange={(e) =>
                        setJobInvoice({
                          ...jobInvoice,
                          invoiceNumber: e.target.value,
                        })
                      }
                    />
                  </Col>
                  {jobInvoice.isTaxable ? (
                    <Col row={6}>
                      <Label>
                        Sales Tax Amount
                        <small className="text-danger ml-1">*</small>
                      </Label>
                      <NumericFormat
                        maxLength={20}
                        allowNegative={false}
                        decimalScale={2}
                        required={true}
                        placeholder="Enter amount.."
                        name="salesTaxAmount"
                        className="height-40p border rounded form-control"
                        value={jobInvoice?.salesTaxAmount || ""}
                        thousandSeparator={true}
                        prefix={"$"}
                        onValueChange={(e) =>
                          setJobInvoice({
                            ...jobInvoice,
                            salesTaxAmount: e.value,
                          })
                        }
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12}>
                    <Label>Amount</Label>
                    <CurrencyInput
                      placeholder="Enter amount.."
                      value={jobInvoice?.rawAmount || ""}
                      required={true}
                      onChange={({ numericValue, rawValue }) =>
                        setJobInvoice({
                          ...jobInvoice,
                          amount: numericValue,
                          rawAmount: rawValue,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={6}>
                    <Label
                      for="attachments"
                      className="m-0 px-2 py1 font-size-75 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faPlus} className="text-primary" />
                      <span className="ml-2">Invoice PDF</span>
                    </Label>
                    <Input
                      type="file"
                      id="attachments"
                      name="attachments"
                      accept=".pdf"
                      onChange={(e) => {
                        const newAttachments = Array.from(e.target.files);
                        setAttachments(newAttachments);
                      }}
                    />
                  </Col>
                  <Col row={6} className="d-flex align-items-center mt-3">
                    <Label className="mb-0 mr-2">Terms:</Label>
                    <CustomInput
                      className="d-inline-block"
                      type="select"
                      id="detailer"
                      value={jobInvoice?.termId || ""}
                      onChange={(e) =>
                        setJobInvoice({
                          ...jobInvoice,
                          termId: +e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Select A Term
                      </option>
                      {terms.map((term, index) => (
                        <option value={term.id} key={`term-${index}`}>
                          {term.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </Row>
                {attachments.length ? (
                  <Row>
                    <ListGroup className="w-100">
                      {attachments.map((file, index) => (
                        <ListGroupItem
                          className="border-0 pt-0 pb-2 d-flex align-items-start"
                          key={`attachments-${index}`}
                        >
                          <span className="mr-2">{file.name}</span>
                          <FontAwesomeIcon
                            size="lg"
                            icon={faWindowClose}
                            className="align-self-center text-danger rounded cursor-pointer"
                            onClick={() => {
                              const newAttachments = attachments.filter(
                                (attachment) => attachment.name !== file.name
                              );
                              setAttachments(newAttachments);
                            }}
                          />
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Row>
                ) : null}
                <Row className="col-12">
                  <Col row={6}>
                    <Label>
                      Sent Date
                      <small className="text-danger ml-1">*</small>
                    </Label>
                    {dateSelectorOpen[SENT_DATE] ? (
                      <DayPicker
                        required={true}
                        className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                        mode="single"
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        defaultMonth={
                          jobInvoice.sentDate
                            ? new Date(moment(jobInvoice.sentDate))
                            : new Date()
                        }
                        selected={
                          jobInvoice.sentDate
                            ? utils.dateForDayPicker(jobInvoice.sentDate)
                            : new Date()
                        }
                        onDayClick={(sentDate) => {
                          setDateSelectorOpen({ [SENT_DATE]: false });
                          setJobInvoice({
                            ...jobInvoice,
                            sentDate: moment(sentDate).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    ) : (
                      <div className="col-12 d-flex z-index-0 p-2 border rounded bg-white cursor-pointer">
                        <FontAwesomeIcon
                          size="lg"
                          icon={faCalendar}
                          className="mr-3 z-index-0 text-secondary"
                          onClick={() =>
                            setDateSelectorOpen({ [SENT_DATE]: true })
                          }
                        />
                        <span
                          onClick={() =>
                            setDateSelectorOpen({ [SENT_DATE]: true })
                          }
                          className="col-8"
                        >
                          {jobInvoice.sentDate
                            ? utils.formatDate(jobInvoice.sentDate)
                            : "Select a Date ..."}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col row={6}>
                    <Label>Paid Date</Label>
                    {dateSelectorOpen[PAID_DATE] ? (
                      <DayPicker
                        required={true}
                        className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                        mode="single"
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        defaultMonth={
                          jobInvoice.paidDate
                            ? new Date(moment(jobInvoice.paidDate))
                            : new Date()
                        }
                        selected={
                          jobInvoice.paidDate
                            ? utils.dateForDayPicker(jobInvoice.paidDate)
                            : new Date()
                        }
                        onDayClick={(paidDate) => {
                          setDateSelectorOpen({ [PAID_DATE]: false });
                          setJobInvoice({
                            ...jobInvoice,
                            paidDate: moment(paidDate).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    ) : (
                      <div className="col-12 d-flex align-items-center p-2 border rounded bg-white cursor-pointer justify-content-between">
                        <FontAwesomeIcon
                          size="lg"
                          icon={faCalendar}
                          className="mr-3 z-index-0 text-secondary"
                          onClick={() =>
                            setDateSelectorOpen({ [PAID_DATE]: true })
                          }
                        />
                        <span
                          onClick={() =>
                            setDateSelectorOpen({ [PAID_DATE]: true })
                          }
                          className="col-8"
                        >
                          {jobInvoice.paidDate
                            ? utils.formatDate(jobInvoice.paidDate)
                            : "Select a Date ..."}
                        </span>
                        <FontAwesomeIcon
                          size="lg"
                          icon={faWindowClose}
                          className="z-index-1 float-right ml-auto align-self-center text-danger rounded"
                          onClick={() =>
                            setJobInvoice({
                              ...jobInvoice,
                              paidDate: null,
                            })
                          }
                          color={"#adb5bd"}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default JobInvoiceModal;
