import React, { useMemo } from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const QuoteRecapLaborSection = ({ quoteItem }) => {
  const itemsWithHours = useMemo(
    () =>
      quoteItem.quoteItemLaborCategories.filter(
        (quoteItemLaborCategory) =>
          !utils.laborCategoriesExcluded.includes(
            +quoteItemLaborCategory.laborCategory.code
          ) && quoteItemLaborCategory.hours > 0
      ),
    [quoteItem]
  );

  const totals = useMemo(
    () =>
      utils.getQuoteItemLaborCategoryTotals({
        quoteItemLaborCategories: itemsWithHours,
        margin: quoteItem.margin,
      }),
    [itemsWithHours, quoteItem]
  );

  return (
    <Table className="col-12 px-0 mt-4 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th width={"35%"} className="align-middle">
            Shop Labor Code/Cat.
          </th>
          <th width={"13%"} className="text-center">
            Est. Hours
          </th>
          <th width={"13%"} className="text-center">
            Cost/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Cost
          </th>
          <th width={"13%"} className="text-center">
            Sale/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Sale
          </th>
        </tr>
      </thead>
      <tbody>
        {itemsWithHours
          .sort((x, y) =>
            parseInt(x.laborCategory.code) < parseInt(y.laborCategory.code)
              ? -1
              : 1
          )
          .map((quoteItemLaborCategory) => {
            const { hours, costByHours, totalCost, saleHour, totalSale } =
              utils.getQuoteItemLaborCategoryRow({
                quoteItemLaborCategory,
                margin: quoteItem.margin,
              });
            return (
              <tr key={quoteItemLaborCategory.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {`${quoteItemLaborCategory.laborCategory.code} - ${quoteItemLaborCategory.laborCategory.name}`}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}

        <tr>
          <td className="font-weight-bold py-0" width={"35%"}>
            Total Shop Labor
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatHour(totals.hours)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalCost / totals.hours)}
            </div>
          </td>
          <td className="py-0">
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalCost)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalSaleHour)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalSale)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapLaborSection;
