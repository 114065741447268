import {
  faFilter,
  faPlus,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

import {
  Button,
  Card,
  Label,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  InputGroupText,
  InputGroup,
  CustomInput,
} from "reactstrap";
import moment from "moment";

import JobModal from "../../components/admin/jobs/JobModal";

import JobsCards from "../../components/admin/jobs/JobsCards";

import InformationModal from "../../components/InformationModal";

import { ACTIONS, MAX_PAGE_SIZE, useJobs } from "../../providers/jobsProvider";

import { jobsApi } from "../../services/jobServices";
import { usersApi } from "../../services/userServices";
import { customersApi } from "../../services/customerServices";
import { utils } from "../../utils/utils";
import {
  ACTIONS as KPI_ACTIONS,
  useJobKPIs,
} from "../../providers/jobsKPIsProvider";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import { useAuth } from "../../providers/authProvider";
import CustomCheckbox from "../../components/CustomCheckbox";
const RFQ = "rfq";
const JOBS = "jobs";

const StatusEnum = {
  1: "Unassigned",
  2: "Waiting for Detailing",
  3: "Detailing",
  4: "Waiting for Check",
  5: "Checking",
  6: "Ready for review",
  7: "Rev Generated",
};

const STATUS_RFQ = 1;
const STATUS_SHIPPED = 2;
const STATUS_PENDING = 3;
const STATUS_IN_PROGRESS = 4;
const STATUS_COMPLETED = 5;
const STATUS_NOT_AWARDED = 6;
const STATUS_NO_QUOTE = 7;
const STATUS_CANCELLED = 8;
const STATUS_INVOICED = 9;
const STATUS_IN_PROJECT_MANAGEMENT = 10;
const STATUS_ARCHIVED = 11;
const STATUS_READY_FOR_INVOICE = 12;
const STATUS_APPROVED = 13;

const PATH_RFQ_ALL = "/rfq/all";
const PATH_RFQ_IN_PROGRESS = "/rfq/in-progress";
const PATH_RFQ_PENDING = "/rfq/pending";
const PATH_RFQ_ORDERED = "/rfq/ordered";
const PATH_RFQ_NO_QUOTE = "/rfq/no-quote";
const PATH_RFQ_NOT_AWARDED = "/rfq/not-awarded";
const PATH_JOBS_IN_PROGRESS = "/jobs/in-progress";
const PATH_JOBS_IN_PROJECT_MANAGEMENT = "/jobs/in-project-management";
const PATH_JOBS_IN_COMPLETED = "/jobs/completed";
const PATH_JOBS_ARCHIVED = "/rfq/archived";
const PATH_JOBS_SHIPPED = "/jobs/shipped";
const PATH_JOBS_READY_FOR_INVOICE = "/jobs/ready-for-invoice";
const PATH_JOBS_INVOICED = "/jobs/invoiced";
const PATH_JOBS_APPROVED = "/jobs/approved";
const PATH_JOBS_PM_APPROVED = "/jobs/pm-approved";
const PATH_JOBS_EXEC_APPROVED = "/jobs/exec-approved";
const PATH_JOBS_ALL = "/jobs/all";

const subRouteStatus = (pathName) => {
  switch (pathName) {
    case PATH_RFQ_ALL:
      return null;
    case PATH_RFQ_IN_PROGRESS:
      return [STATUS_RFQ];
    case PATH_RFQ_PENDING:
      return [STATUS_PENDING];
    case PATH_RFQ_ORDERED:
      return [
        STATUS_COMPLETED,
        STATUS_IN_PROGRESS,
        STATUS_CANCELLED,
        STATUS_INVOICED,
      ];
    case PATH_RFQ_NO_QUOTE:
      return [STATUS_NO_QUOTE];
    case PATH_RFQ_NOT_AWARDED:
      return [STATUS_NOT_AWARDED];
    case PATH_JOBS_IN_PROGRESS:
      return [STATUS_IN_PROGRESS];
    case PATH_JOBS_IN_PROJECT_MANAGEMENT:
      return [STATUS_IN_PROJECT_MANAGEMENT];
    case PATH_JOBS_IN_COMPLETED:
      return [STATUS_COMPLETED];
    case PATH_JOBS_SHIPPED:
      return [STATUS_SHIPPED];
    case PATH_JOBS_READY_FOR_INVOICE:
      return [STATUS_READY_FOR_INVOICE];
    case PATH_JOBS_INVOICED:
    case PATH_JOBS_PM_APPROVED:
    case PATH_JOBS_EXEC_APPROVED:
      return [STATUS_INVOICED];
    case PATH_JOBS_APPROVED:
      return [STATUS_APPROVED];
    case PATH_JOBS_ALL:
      return [
        STATUS_COMPLETED,
        STATUS_IN_PROGRESS,
        STATUS_SHIPPED,
        STATUS_CANCELLED,
        STATUS_READY_FOR_INVOICE,
        STATUS_INVOICED,
        STATUS_APPROVED,
        STATUS_IN_PROJECT_MANAGEMENT,
      ];
    case PATH_JOBS_ARCHIVED:
      return [STATUS_ARCHIVED];
    default:
      return null;
  }
};

const subRouteLabel = (pathName) => {
  switch (pathName) {
    case PATH_JOBS_ALL:
      return "All Jobs";
    case PATH_RFQ_IN_PROGRESS:
      return "RFQs In Estimating";
    case PATH_RFQ_ALL:
      return "All RFQs";
    case PATH_RFQ_PENDING:
      return "Pending RFQs";
    case PATH_RFQ_ORDERED:
      return "Ordered RFQs";
    case PATH_RFQ_NO_QUOTE:
      return "No Quote RFQs";
    case PATH_RFQ_NOT_AWARDED:
      return "Not Awarded RFQs";
    case PATH_JOBS_IN_PROJECT_MANAGEMENT:
      return "In Project Management Jobs";
    case PATH_JOBS_IN_PROGRESS:
      return "In Progress Jobs";
    case PATH_JOBS_SHIPPED:
      return "Shipped Jobs";
    case PATH_JOBS_READY_FOR_INVOICE:
      return "Ready For Invoice Jobs";
    case PATH_JOBS_INVOICED:
      return "Invoiced Jobs";
    case PATH_JOBS_PM_APPROVED:
      return "Jobs with PM Approved";
    case PATH_JOBS_EXEC_APPROVED:
      return "Jobs with Exec Approved";
    case PATH_JOBS_APPROVED:
      return "Approved Jobs";
    case PATH_JOBS_IN_COMPLETED:
      return "Completed Jobs";
    case PATH_JOBS_ARCHIVED:
      return "Archived RFQs";
    default:
      return null;
  }
};

const columns = [
  {
    id: "id",
    accessor: "id",
    Header: "RFQ#",
    headerProps: { className: "text-truncate" },
    width: 100,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ARCHIVED,
    ],
    Cell: (rowData) => {
      const { id } = rowData.row.original;
      return id.toString().padStart(5, "0");
    },
  },
  {
    id: "jobNumber",
    accessor: "jobNumber",
    Header: "Job#",
    headerProps: { className: "text-truncate" },
    width: 100,
    enabledFor: [
      PATH_RFQ_ORDERED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { jobNumber } = rowData.row.original;
      return jobNumber || "-";
    },
  },
  {
    id: "customer",
    accessor: "customer",
    disableSortBy: true,
    Header: "Customer",
    headerProps: { className: "text-truncate" },
    width: 250,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_JOBS_ARCHIVED,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_ORDERED,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { customer } = rowData.row.original;
      return customer ? `${customer?.name}` : "-";
    },
  },
  {
    id: "customerPONumber",
    accessor: "customerPONumber",
    Header: "PO#",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
  },
  {
    id: "description",
    accessor: "description",
    Header: "Description",
    headerProps: { className: "text-truncate" },
    width: 250,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_JOBS_ARCHIVED,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_ORDERED,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { description } = rowData.row.original;
      return description || "-";
    },
  },
  {
    id: "contractAmount",
    accessor: "contractAmount",
    Header: "Contract Amount",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { contractAmount } = rowData.row.original;
      return contractAmount ? `${utils.formatCurrency(contractAmount)}` : "-";
    },
  },
  {
    id: "totalManHours",
    accessor: "totalManHours",
    Header: "Total MH",
    headerProps: { className: "text-truncate" },
    width: 150,
    disableSortBy: true,
    enabledFor: [
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      return `${utils.getManHours(rowData.row.original)} hours`;
    },
  },
  {
    id: "contractDate",
    accessor: "contractDate",
    disableSortBy: true,
    Header: "Contract Date",
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ORDERED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { contractDate } = rowData.row.original;
      return contractDate ? utils.formatDate(contractDate) : "-";
    },
  },
  {
    id: "updatedShipDate",
    accessor: "updatedShipDate",
    disableSortBy: true,
    Header: "Updated Ship Date",
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_JOBS_IN_PROGRESS,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
    ],
    Cell: (rowData) => {
      const { updatedShipDate } = rowData.row.original;
      return updatedShipDate ? utils.formatDate(updatedShipDate) : "-";
    },
  },
  {
    id: "daysLeftToShipDate",
    accessor: "daysLeftToShipDate",
    disableSortBy: true,
    Header: "Days Left to Ship Date",
    headerProps: { className: "text-truncate" },
    width: 180,
    enabledFor: [PATH_JOBS_IN_PROGRESS],
    Cell: (rowData) => {
      const { updatedShipDate } = rowData.row.original;
      const today = moment();
      const shipDate = moment(updatedShipDate);
      let daysLeft = shipDate.diff(today, "days");

      if (shipDate.diff(today, "hours") % 24 > 0) {
        daysLeft += 1;
      }

      return daysLeft || "-";
    },
  },
  {
    id: "manager",
    accessor: "manager",
    Header: "Project Manager",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [PATH_RFQ_ORDERED],
    Cell: (rowData) => {
      const { manager } = rowData.row.original;
      return manager ? `${manager.firstName} ${manager.lastName} ` : "-";
    },
  },
  {
    id: "estimatedShipDate",
    accessor: "estimatedShipDate",
    disableSortBy: true,
    Header: "Est. Ship Date",
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ORDERED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_PROJECT_MANAGEMENT,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { estimatedShipDate } = rowData.row.original;
      return estimatedShipDate ? utils.formatDate(estimatedShipDate) : "-";
    },
  },
  {
    id: "detailingStatus",
    accessor: "detailingStatus",
    disableSortBy: true,
    Header: "Detailing Status",
    headerProps: { className: "text-truncate" },
    width: 190,
    enabledFor: [PATH_JOBS_IN_PROJECT_MANAGEMENT],
    Cell: (rowData) => {
      const { sequence } = rowData.row.original;
      return sequence.length > 0
        ? StatusEnum[sequence[0].sequenceStatusId]
        : "-";
    },
  },
  {
    id: "shippedCompleteDate",
    accessor: "shippedCompleteDate",
    disableSortBy: true,
    Header: "Shipped Complete Date",
    headerProps: { className: "text-truncate" },
    width: 190,
    enabledFor: [
      PATH_RFQ_ORDERED,
      PATH_JOBS_ALL,
      PATH_JOBS_IN_COMPLETED,
      PATH_JOBS_SHIPPED,
      PATH_JOBS_READY_FOR_INVOICE,
      PATH_JOBS_INVOICED,
      PATH_JOBS_PM_APPROVED,
      PATH_JOBS_EXEC_APPROVED,
      PATH_JOBS_APPROVED,
    ],
    Cell: (rowData) => {
      const { shippedCompleteDate } = rowData.row.original;
      return shippedCompleteDate ? utils.formatDate(shippedCompleteDate) : "-";
    },
  },
  {
    id: "estimatorUser",
    accessor: "estimatorUser",
    Header: "Estimator",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ARCHIVED,
    ],
    Cell: (rowData) => {
      const { estimatorUser } = rowData.row.original;
      return estimatorUser
        ? `${estimatorUser?.firstName} ${estimatorUser?.lastName}`
        : "-";
    },
  },
  {
    id: "totalQuote",
    accessor: "totalQuote",
    Header: "Total Quote",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ARCHIVED,
    ],
    Cell: (rowData) => {
      return utils.formatCurrency(
        utils.doCalculations(rowData.row.original).salePrice
      );
    },
  },
  {
    id: "totalShopLabor",
    accessor: "totalShopLabor",
    Header: "Total Shop Labor",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [PATH_RFQ_PENDING, PATH_JOBS_ARCHIVED],
    Cell: (rowData) => {
      return utils.formatHour(
        utils.doCalculations(rowData.row.original).shopHours
      );
    },
  },
  {
    id: "receivedDate",
    accessor: "receivedDate",
    Header: "Received Date",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_PENDING,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
      PATH_JOBS_ARCHIVED,
    ],
    Cell: (rowData) => {
      const { receivedDate } = rowData.row.original;
      return receivedDate ? utils.formatDate(receivedDate) : "-";
    },
  },
  {
    id: "quotedDate",
    accessor: "quotedDate",
    disableSortBy: true,
    Header: "Quoted Date",
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [PATH_RFQ_PENDING, PATH_JOBS_ARCHIVED],
    Cell: (rowData) => {
      const { estimatedShipDate } = rowData.row.original;
      return estimatedShipDate ? utils.formatDate(estimatedShipDate) : "-";
    },
  },
  {
    id: "quoteDue",
    accessor: "quoteDue",
    Header: "Quote Due",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 150,
    enabledFor: [
      PATH_RFQ_ALL,
      PATH_RFQ_IN_PROGRESS,
      PATH_RFQ_NO_QUOTE,
      PATH_RFQ_NOT_AWARDED,
    ],
    Cell: (rowData) => {
      const { quoteDue } = rowData.row.original;
      return quoteDue ? utils.formatDate(quoteDue) : "-";
    },
  },
  {
    id: "jobStatus",
    accessor: "jobStatus",
    Header: "Status",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    width: 250,
    enabledFor: [PATH_RFQ_ALL, PATH_JOBS_ALL],
    Cell: (rowData) => {
      const { jobStatus } = rowData.row.original;
      return jobStatus ? jobStatus.name : "-";
    },
  },
];

const Jobs = () => {
  const [jobsContext, setJobsContext] = useJobs();
  const [, setJobKPIsContext] = useJobKPIs();
  const [authContext] = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [createJobModal, setCreateJobModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [estimatorId, setEstimatorId] = useState();
  const [managerId, setManagerId] = useState();
  const [customerId, setCustomerId] = useState();
  const [currentYear, setCurrentYear] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const isAdmin = utils.isAdmin(authContext.currentUser?.role?.id);
  const currentUserId = authContext.currentUser?.id;

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          !sortBy ||
          (jobsContext.sortBy === sortBy.id &&
            jobsContext.direction === direction)
        ) {
          return;
        }
        setJobsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setJobsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setJobsContext, jobsContext.direction, jobsContext.sortBy]
  );

  const details = (jobId) =>
    navigate(
      `/${location.pathname.includes(RFQ) ? RFQ : JOBS}/${jobId}/details`
    );

  useEffect(() => {
    setJobsContext({
      action: ACTIONS.PAGE_CHANGE,
      payload: { page: 1 },
    });
  }, [setJobsContext]);

  useEffect(() => {
    const fetchData = async () => {
      const users = await usersApi.getUsers({
        pageSize: MAX_PAGE_SIZE,
      });
      setUsers(users?.data || []);
      const customers = await customersApi.getCustomers({
        pageSize: MAX_PAGE_SIZE,
      });
      setCustomers(customers?.data || []);
    };
    fetchData();
  }, [setJobsContext]);

  useEffect(() => {
    setManagerId();
    setEstimatorId();
  }, [location.pathname]);

  useEffect(() => {
    const pmApproval = location.pathname === PATH_JOBS_PM_APPROVED;
    const execApproval = location.pathname === PATH_JOBS_EXEC_APPROVED;
    setLoading(true);
    jobsApi
      .getJobs({
        search: jobsContext.search,
        page: jobsContext.page - 1,
        pageSize: jobsContext.sizePerPage,
        sortBy: jobsContext.sortBy,
        direction: jobsContext.direction,
        statusId: subRouteStatus(location.pathname),
        estimatorId:
          location.pathname.includes(RFQ) && !isAdmin
            ? currentUserId
            : estimatorId,
        managerId:
          location.pathname.includes(JOBS) && !isAdmin
            ? currentUserId
            : managerId,
        customerId,
        currentYear: location.pathname.includes(JOBS) ? currentYear : null,
        pmApproval,
        execApproval,
      })
      .then((jobs) => {
        setJobsContext({
          action: ACTIONS.GET_JOBS_SUCCESS,
          payload: { jobs },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setJobsContext,
    jobsContext.sortBy,
    jobsContext.direction,
    jobsContext.sizePerPage,
    jobsContext.page,
    jobsContext.search,
    jobsContext.refresh,
    location.pathname,
    estimatorId,
    managerId,
    customerId,
    currentUserId,
    isAdmin,
    currentYear,
  ]);

  useEffect(() => {
    setJobsContext({
      action: ACTIONS.SORT,
      payload: {
        sortBy:
          subRouteStatus(location.pathname) &&
          (subRouteStatus(location.pathname).includes(STATUS_COMPLETED) ||
            subRouteStatus(location.pathname).includes(STATUS_IN_PROGRESS))
            ? "jobNumber"
            : "id",
        direction: "desc",
      },
    });
  }, [location.pathname, setJobsContext]);

  useEffect(() => {
    const fetchData = async () => {
      setJobKPIsContext({
        action: KPI_ACTIONS.GET_DATA_LOADING,
        payload: true,
      });
      const kpis = await jobsApi.getJobsKPIs({});
      setJobKPIsContext({
        action: KPI_ACTIONS.GET_DATA_SUCCESS,
        payload: { kpis },
      });
    };
    fetchData();
  }, [setJobKPIsContext]);

  return (
    <Container fluid>
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns.filter((column) =>
            column.enabledFor.includes(location.pathname)
          )}
          data={jobsContext.jobs?.data || []}
          pageSize={jobsContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: jobsContext.sortBy,
            direction: jobsContext.direction,
          }}
        >
          <JobsCards />
          <Card className="col-12 px-0 overflow-x-auto">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">{subRouteLabel(location.pathname)}</h3>
                <small className="text-muted ml-2 pt-1">
                  ({jobsContext?.jobs?.count})
                </small>
              </div>
              <div className=" d-flex align-items-center justify-content-between">
                {location.pathname.includes(JOBS) ? (
                  <div
                    className="input-group justify-content-end mr-3"
                    onClick={() => setCurrentYear(!currentYear)}
                  >
                    <CustomCheckbox checked={currentYear} />
                    <Label className="mb-0 ml-2">Current Year</Label>
                  </div>
                ) : null}
                {location.pathname.includes(RFQ) ||
                location.pathname.includes(JOBS) ? (
                  <InputGroup size="m" className="mr-3">
                    <CustomInput
                      className="border"
                      id="customerSelect"
                      type="select"
                      name="customerSelect"
                      value={customerId}
                      onChange={(e) => setCustomerId(e.currentTarget.value)}
                    >
                      <option value={""}>Filter by customer..</option>
                      {customers.map((customer) => (
                        <option
                          key={customer.id}
                          value={customer.id}
                        >{`${customer.name}`}</option>
                      ))}
                    </CustomInput>
                    <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                      <FontAwesomeIcon icon={faFilter} size="sm" />
                    </InputGroupText>
                  </InputGroup>
                ) : null}
                {location.pathname.includes(RFQ) ? (
                  isAdmin ? (
                    <InputGroup size="m" className="mr-3">
                      <CustomInput
                        key="estimatorSelect"
                        className="border"
                        id="estimatorSelect"
                        type="select"
                        name="estimatorSelect"
                        value={estimatorId}
                        onChange={(e) => setEstimatorId(e.currentTarget.value)}
                      >
                        <option value={""}>Filter by estimator..</option>
                        {users
                          .filter((user) => user.jobNumberSuffix)
                          .map((user) => (
                            <option
                              key={user.id}
                              value={user.id}
                            >{`${user.firstName} ${user.lastName}`}</option>
                          ))}
                      </CustomInput>
                      <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                        <FontAwesomeIcon icon={faFilter} size="sm" />
                      </InputGroupText>
                    </InputGroup>
                  ) : null
                ) : location.pathname.includes(JOBS) ? (
                  isAdmin ? (
                    <InputGroup size="m" className="mr-3">
                      <CustomInput
                        key="managerSelect"
                        className="border"
                        id="managerSelect"
                        type="select"
                        name="managerSelect"
                        value={managerId}
                        onChange={(e) => setManagerId(e.currentTarget.value)}
                      >
                        <option value={""}>Filter by manager..</option>
                        {users
                          .filter((user) => user.jobNumberSuffix)
                          .map((user) => (
                            <option
                              key={user.id}
                              value={user.id}
                            >{`${user.firstName} ${user.lastName}`}</option>
                          ))}
                      </CustomInput>
                      <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                        <FontAwesomeIcon icon={faFilter} size="sm" />
                      </InputGroupText>
                    </InputGroup>
                  ) : null
                ) : null}
                <InputGroup size="m" className="mr-3">
                  <DebounceInput
                    minLength={2}
                    debounceTimeout={500}
                    className="form-control"
                    placeholder="Search for.."
                    value={jobsContext.search}
                    onChange={(evt) =>
                      setJobsContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                {!subRouteStatus(location.pathname) ? (
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle custom-rounded-button d-flex "
                    color="primary"
                    onClick={() => setCreateJobModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex "
                  color="primary"
                  onClick={() =>
                    setJobsContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                onRowClick={(row) => details(row.original.id)}
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100, 250]}
                totalCount={jobsContext.jobs?.count}
                pageCount={jobsContext.jobs?.totalPages}
                currentPage={jobsContext.page - 1}
                onPageChange={(page) =>
                  setJobsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={jobsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setJobsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {createJobModal && (
        <JobModal
          onClose={() => {
            setCreateJobModal(false);
          }}
          onSubmit={(id) => {
            setCreateJobModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create Job",
              body: "Job created successfully.",
            });
            navigate(`/jobs/${id}/details`);
          }}
        />
      )}
    </Container>
  );
};

export default Jobs;
