import React, { useEffect, useState, useCallback } from "react";
import classnames from "classnames";
import {
  Button,
  CustomInput,
  Col,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import { usersApi } from "../../../services/userServices";

const WAITING_FOR_ASSIGNMENT_STATUS = 1;
const WAITING_FOR_DETAILER_STATUS = 2;
const DETAILING_IN_PROGRESS_STATUS = 3;
const WAITING_FOR_CHECKING_STATUS = 4;
const CHECKING_IN_PROGRESS_STATUS = 5;
const CHECKING_COMPLETE_STATUS = 6;
const REVISION_GENERATED_STATUS = 7;

const SEQUENCE_STATUSES = [
  { status: "Waiting for Assignment", value: WAITING_FOR_ASSIGNMENT_STATUS },
  { status: "Waiting for Detailer", value: WAITING_FOR_DETAILER_STATUS },
  { status: "Detailing in Progress", value: DETAILING_IN_PROGRESS_STATUS },
  { status: "Waiting for Checking", value: WAITING_FOR_CHECKING_STATUS },
  { status: "Checking in Progress", value: CHECKING_IN_PROGRESS_STATUS },
  { status: "Checking Complete", value: CHECKING_COMPLETE_STATUS },
  { status: "Revision Generated", value: REVISION_GENERATED_STATUS },
];
const DEFAULT_SEQUENCE_STATUSES = [
  { status: "Waiting for Assignment", value: WAITING_FOR_ASSIGNMENT_STATUS },
];

const SequenceDetailsModal = ({
  sequence,
  detailModal,
  onSubmit,
  onClear,
  readOnly,
  setInformationModal = () => {},
  checkerId,
  setCheckerId = () => {},
  detailerId,
  setDetailerId = () => {},
  enableUpdate,
  statusId,
  setStatusId = () => {},
}) => {
  const [defaultCheckers, setDefaultCheckers] = useState([]);
  const [checkers, setCheckers] = useState([]);
  const [detailers, setDetailers] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const detailer =
    detailerId ||
    `${sequence?.detailer?.firstName} ${sequence?.detailer?.lastName}`;
  const checker =
    checkerId ||
    `${sequence?.checker?.firstName} ${sequence?.checker?.lastName}`;

  const optionChange = useCallback(
    (userId, isDetailer, users = defaultCheckers) => {
      const usersFiltered = users.filter((user) => user?.id !== +userId);
      if (isDetailer) {
        setCheckers(usersFiltered);
        setDetailerId(userId || null);
      } else {
        setDetailers(usersFiltered);
        setCheckerId(userId || null);
      }
    },
    [defaultCheckers, setCheckerId, setDetailerId]
  );
  const assignmentValidation =
    checkerId && checkerId !== "-" && detailerId && detailerId !== "-";

  // Load users
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!defaultCheckers?.length) {
          const data = await usersApi.getUsers({ pageSize: 10000 });
          setDefaultCheckers(data.data);
          optionChange(detailerId, true, data.data);
          optionChange(checkerId, false, data.data);
        }
        if (!selectedTab && sequence?.revisions?.length) {
          setSelectedTab(sequence?.revisions[0]?.id);
        }
      } catch (err) {
        setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [
    setInformationModal,
    defaultCheckers,
    detailerId,
    checkerId,
    optionChange,
    selectedTab,
    sequence?.revisions,
  ]);

  return (
    <Modal isOpen={detailModal} className="modal-sm">
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <Row className="mt-2 mx-2">
            <ListGroup className="w-100">
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Customer</span>
                <span>{sequence?.job?.customer?.name || "-"}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Job#</span>
                <span>{sequence?.job?.jobNumber || "-"}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Project Manager</span>
                <span>
                  {sequence?.job?.manager
                    ? `${sequence?.job?.manager?.firstName} ${sequence?.job?.manager?.lastName}`
                    : "-"}
                </span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Due Date</span>
                <span>{sequence?.revisionDate || "-"}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Job Desc.</span>
                <span>{sequence?.description || "-"}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">MP Notes</span>
                <span>{sequence?.notes || "-"}</span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                <span className="font-weight-bold">Review Process</span>
                <span>{sequence?.reviewProcess?.description || "-"}</span>
              </ListGroupItem>
              <ListGroupItem>
                <FormGroup className="w-100 d-flex justify-content-between mb-0">
                  <Label className="mb-0 font-weight-bold" for="detailer">
                    Detailer
                  </Label>
                  {readOnly ? (
                    <span>{detailer || "-"}</span>
                  ) : (
                    <CustomInput
                      className="d-inline-block w-auto"
                      type="select"
                      id="detailer"
                      value={detailerId || ""}
                      onChange={(e) => optionChange(e.target.value, true)}
                    >
                      <option value="">Select Detailer</option>
                      {detailers
                        .filter((detailer) => detailer?.id !== +checkerId)
                        .map((detailer) => (
                          <option
                            value={detailer.id}
                            key={`detailer-${detailer.id}`}
                          >{`${detailer?.firstName} ${detailer?.lastName}`}</option>
                        ))}
                    </CustomInput>
                  )}
                </FormGroup>
              </ListGroupItem>
              <ListGroupItem>
                <FormGroup className="w-100 d-flex justify-content-between mb-0">
                  <Label className="mb-0 font-weight-bold" for="checker">
                    Checker
                  </Label>
                  {readOnly ? (
                    <span>{checker || "-"}</span>
                  ) : (
                    <CustomInput
                      className="d-inline-block w-auto"
                      type="select"
                      id="checker"
                      value={checkerId || ""}
                      onChange={(e) => optionChange(e.target.value)}
                    >
                      <option value="">Select Checker</option>
                      {checkers
                        .filter((checker) => checker?.id !== +detailerId)
                        .map((checker) => (
                          <option
                            value={checker.id}
                            key={`checker-${checker.id}`}
                          >{`${checker?.firstName} ${checker?.lastName}`}</option>
                        ))}
                    </CustomInput>
                  )}
                </FormGroup>
              </ListGroupItem>
              {enableUpdate ? (
                <ListGroupItem>
                  <FormGroup className="w-100 d-flex justify-content-between mb-0">
                    <Label className="mb-0 font-weight-bold" for="checker">
                      Status
                    </Label>
                    <CustomInput
                      className="d-inline-block w-auto"
                      type="select"
                      id="checker"
                      value={statusId || ""}
                      onChange={(e) => setStatusId(+e.target.value)}
                    >
                      <option value={""} disabled>
                        Select A Status
                      </option>
                      {(assignmentValidation
                        ? SEQUENCE_STATUSES
                        : DEFAULT_SEQUENCE_STATUSES
                      ).map((item) => (
                        <option value={item.value} key={`item-${item.value}`}>
                          {item.status}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </ListGroupItem>
              ) : null}
            </ListGroup>
            {sequence?.revisions?.length ? (
              <div className="flex-grow-1 overflow-x-auto">
                <Row className="m-0">
                  <div>
                    <Nav
                      tabs
                      className="text-center flex-nowrap job-details-nav cursor-pointer mt-2"
                    >
                      <NavItem key={`link-${sequence.revisions[0].id}`}>
                        <NavLink
                          className={classnames({
                            active: selectedTab === sequence.revisions[0].id,
                          })}
                          onClick={() =>
                            setSelectedTab(sequence.revisions[0].id)
                          }
                        >
                          {sequence.revisions[0].name}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={selectedTab}>
                      <TabPane
                        tabId={sequence.revisions[0].id}
                        key={`tab-pane-${sequence.revisions[0].id}`}
                      >
                        <Card className="box-shadow-none border-left border-bottom border-right mb-0">
                          <CardBody className="px-2 pb-2">
                            <strong className="ml-2">Notes: </strong>
                            {sequence.revisions[0].notes}
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </div>
                </Row>
              </div>
            ) : null}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row>
              {readOnly && !enableUpdate ? (
                <Button
                  color={"secondary"}
                  onClick={() => {
                    setSelectedTab();
                    onClear();
                  }}
                >
                  Close
                </Button>
              ) : (
                <div className="d-flex justify-content-between w-100">
                  <Button
                    color={"secondary"}
                    onClick={() => {
                      setSelectedTab();
                      onClear();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button color={"primary"} type="submit">
                    Save
                  </Button>
                </div>
              )}
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SequenceDetailsModal;
