import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import NumericFormat from "../../../../NumericFormat";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import {
  ACTIONS,
  useQuoteRecap,
} from "../../../../../providers/quoteRecapProvider";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { utils } from "../../../../../utils/utils";
import { useEvaOverride } from "../../../../../providers/evaOverrideProvider";

const EstimatedActualTotals = () => {
  const [evaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [quoteItemsContext] = useQuoteItems();
  const [jobDetails] = useJobDetails();
  const [quoteRecapContext, setQuoteRecapContext] = useQuoteRecap();

  const [actualData, setActualData] = useState({
    totalCost: 0,
    marginAmount: 0,
    salePrice: 0,
    salePerLB: 0,
    marginPercent: 0,
  });

  useEffect(() => {
    if (quoteItemsContext.quoteItems) {
      setQuoteRecapContext({
        action: ACTIONS.ON_DO_CALCULATIONS,
        payload: {
          quoteItems: quoteItemsContext.quoteItems,
          job: jobDetails.job,
        },
      });
    }
  }, [quoteItemsContext, setQuoteRecapContext, jobDetails.job]);

  useEffect(() => {
    const { totalCost, marginAmount, marginPercent, salePerLB, salePrice } =
      utils.calculateEVAData(
        commonDataContext,
        evaOverrideContext,
        jobDetails.job,
        quoteRecapContext
      );

    setActualData({
      totalCost,
      marginAmount,
      marginPercent,
      salePerLB,
      salePrice,
    });
  }, [
    evaOverrideContext,
    quoteItemsContext,
    jobDetails.job,
    quoteRecapContext,
    commonDataContext,
  ]);

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0 font-weight-bold">
      <tbody>
        <tr>
          <td className="py-0 text-truncate" width={"45%"}>
            Sale Price
          </td>
          <td className="py-0" width={"15%"}>
            <NumericFormat
              maxLength={20}
              allowNegative={false}
              decimalScale={2}
              className="m-1 form-control border text-right"
              value={quoteRecapContext["salePrice"] || 0}
              readOnly={true}
              thousandSeparator={true}
              prefix={"$"}
              displayType="text"
            />
          </td>
          <td className="py-0" width={"15%"}>
              <NumericFormat
              maxLength={20}
              allowNegative={false}
              decimalScale={2}
              className="m-1 form-control border text-right"
              value={actualData["salePrice"] || 0}
              readOnly={true}
              thousandSeparator={true}
              prefix={"$"}
              displayType="text"
            />
          </td>
          <td className="py-0" width={"15%"} />
        </tr>
        <tr>
          <td className="py-0 text-truncate" width={"45%"}>
            Total Cost
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(quoteRecapContext["totalCost"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualData["totalCost"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`${
                quoteRecapContext["totalCost"] - actualData["totalCost"] < 0
                  ? "text-danger"
                  : "text-success"
              } form-control border text-right m-1 font-weight-bold`}
            >
              {utils.formatCurrency(
                quoteRecapContext["totalCost"] - actualData["totalCost"]
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-0 text-truncate" width={"45%"}>
            Margin Amount
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(quoteRecapContext["marginAmount"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualData["marginAmount"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`${
                actualData["marginAmount"] - actualData["marginAmount"] < 0
                  ? "text-danger"
                  : "text-success"
              } form-control border text-right m-1 font-weight-bold`}
            >
              {utils.formatCurrency(
                actualData["marginAmount"] - quoteRecapContext["marginAmount"]
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-0 text-truncate" width={"45%"}>
            Sale Per Lb.
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(quoteRecapContext["salePerLB"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualData["salePerLB"])}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`${
                quoteRecapContext["salePerLB"] - actualData["salePerLB"] < 0
                  ? "text-danger"
                  : "text-success"
              } form-control border text-right m-1 font-weight-bold`}
            >
              {utils.formatCurrency(
                quoteRecapContext["salePerLB"] - actualData["salePerLB"]
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td className="py-0 text-truncate" width={"45%"}>
            Margin Percent
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatPercent(
                quoteRecapContext["marginPercent"] || 0,
                100
              )}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatPercent(actualData["marginPercent"] || 0, 100)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`${
                actualData["marginPercent"] -
                  quoteRecapContext["marginPercent"] >
                0
                  ? "text-success"
                  : "text-danger"
              } form-control border text-right m-1 font-weight-bold`}
            >
              {utils.formatPercent(
                actualData["marginPercent"] -
                  quoteRecapContext["marginPercent"] || 0,
                100
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimatedActualTotals;
