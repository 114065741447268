import moment from "moment";

const WG_MULTIPLIER = 0.06;

const STATUS_IN_PROGRESS = 4;
const STATUS_COMPLETED = 5;
const STATUS_CANCELLED = 8;
const STATUS_INVOICED = 9;
const STATUS_IN_PROJECT_MANAGEMENT = 10;
const STATUS_SHIPPED_COMPLETE = 2;
const STATUS_READY_FOR_INVOICE = 12;
const STATUS_APPROVED = 13;
const RELESASE_DATE = "20240622";

const ADMIN_ROLE_ID = 1;
const DETAILING_MANAGER_ROLE_ID = 3;
const DETAILER_CHECKER_ROLE_ID = 4;
const PROJECT_ASSISTANT_ROLE_ID = 5;

const ADMIN_ROLE_IDS = [
  ADMIN_ROLE_ID,
  DETAILING_MANAGER_ROLE_ID,
  DETAILER_CHECKER_ROLE_ID,
  PROJECT_ASSISTANT_ROLE_ID,
];

const NEW_JOBS_TAB = "NEW_JOBS_TAB";
const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";
const DETAILING_TAB = "DETAILING_TAB";
const WAITING_FOR_CHECKING_TAB = "WAITING_FOR_CHECKING_TAB";
const REVISION_GENERATED_TAB = "REVISION_GENERATED_TAB";
const CHECKING_IN_PROGRESS_TAB = "CHECKING_IN_PROGRESS_TAB";
const CHECKING_COMPLETE_TAB = "CHECKING_COMPLETE_TAB";

const DETAILING_TABS = {
  1: NEW_JOBS_TAB,
  2: WAITING_FOR_DETAILING_TAB,
  3: DETAILING_TAB,
  4: WAITING_FOR_CHECKING_TAB,
  5: CHECKING_IN_PROGRESS_TAB,
  6: CHECKING_COMPLETE_TAB,
  7: REVISION_GENERATED_TAB,
};

const VALID_JOB_STATUSES = [
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_IN_PROJECT_MANAGEMENT,
  STATUS_INVOICED,
  STATUS_SHIPPED_COMPLETE,
  STATUS_READY_FOR_INVOICE,
  STATUS_APPROVED,
];

const STRUCTURAL_ROLL_CODE = 9;
const TUBE_PLASMA_CODE = 10;
const SHEAR_CODE = 11;
const LABOR_CATEGORIES_EXCLUDED = [
  STRUCTURAL_ROLL_CODE,
  TUBE_PLASMA_CODE,
  SHEAR_CODE,
];

const dateOptions = ["20240601", "20240101"];

const formatDecimal = (number, maximumFractionDigits = 2, asNumber) => {
  number = typeof number === "string" ? number.replace(/,/g, "") : number;
  const isFloat = !Number.isInteger(+number);
  let roundedNumber = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: isFloat ? maximumFractionDigits : 0,
    minimumFractionDigits: isFloat ? maximumFractionDigits : 0,
  }).format(number);

  if (asNumber) {
    roundedNumber = roundedNumber.replace(/,/g, "");
    roundedNumber = parseFloat(roundedNumber);
  }
  return roundedNumber;
};

const formatCurrency = (number = 0, maximumFractionDigits = 2) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
  }).format(number);
};

// for an objects array, format currency for the fields given
const formatCurrencyToObjectsInArray = (data, fields) => {
  return data.map((item) => {
    fields.forEach((field) => {
      item[field] = formatCurrency(item[field]);
    });
    return item;
  });
};

const formatDate = (date, format = "MM/DD/YYYY") => {
  return (date ? moment(date) : moment()).format(format);
};

// for an objects array, format the dates on the fields given (only if the given date is a valid date)
const formatDatesToObjectsInArray = (data, fields) => {
  return data.map((item) => {
    fields.forEach((field) => {
      if (item[field] && item[field].length === 10) {
        item[field] = formatDate(item[field]);
      }
    });
    return item;
  });
};

const getFieldNameToUseAsCostByHour = (job) => {
  let fieldToUse = "default";
  dateOptions.some((date) => {
    const validation = moment(job.createdAt).isSameOrAfter(date);
    if (validation) {
      fieldToUse = date;
    }
    return validation;
  });
  return fieldToUse;
};

export const utils = {
  WG_MULTIPLIER,
  RELESASE_DATE,
  DETAILING_MANAGER_ROLE_ID,
  DETAILER_CHECKER_ROLE_ID,
  getQuoteItemManagementRoleTotals: ({
    margin = 0,
    quoteItemManagementRoles,
  }) =>
    quoteItemManagementRoles.reduce(
      (p, c) => {
        const row = utils.getQuoteItemManagementRoleRow({
          quoteItemManagementRole: c,
          margin,
        });
        p.hours += row.hours;
        p.totalCost += row.totalCost;
        p.totalSale += row.totalSale;
        return p;
      },
      { hours: 0, totalCost: 0, totalSale: 0 }
    ),
  getQuoteItemManagementRoleRow: ({ margin, quoteItemManagementRole }) => {
    const fieldName = utils.getFieldNameToUseAsCostByHour(
      quoteItemManagementRole
    );
    const hours = utils.roundBasedOnThirdDecimalDigit(
      quoteItemManagementRole.hours || 0
    );
    const costByHour = utils.roundBasedOnThirdDecimalDigit(
      quoteItemManagementRole.managementRole.costByHoursAndYear[fieldName] || 0
    );
    const totalCost = utils.roundBasedOnThirdDecimalDigit(
      hours * costByHour || 0
    );
    const saleByHour = utils.roundBasedOnThirdDecimalDigit(
      costByHour * (1 + margin / 100) || 0
    );
    const totalSale = utils.roundBasedOnThirdDecimalDigit(
      hours * costByHour * (1 + margin / 100) || 0
    );
    return { hours, costByHour, totalCost, saleByHour, totalSale };
  },
  getQuoteItemLaborCategoryTotals: ({
    margin = 0,
    quoteItemLaborCategories,
    laborCategoriesExcluded = [],
  }) =>
    quoteItemLaborCategories
      .filter((quoteItemLaborCategory) =>
        laborCategoriesExcluded.length
          ? !laborCategoriesExcluded.includes(
              +quoteItemLaborCategory.laborCategory.code
            ) && quoteItemLaborCategory.hours > 0
          : true
      )
      .reduce(
        (p, c) => {
          const row = utils.getQuoteItemLaborCategoryRow({
            quoteItemLaborCategory: c,
            margin,
          });
          p.hours += row.hours;
          p.totalSaleHour += row.saleHour;
          p.totalCost += row.totalCost;
          p.totalSale += row.totalSale;
          return p;
        },
        { hours: 0, totalCost: 0, totalSale: 0, totalSaleHour: 0 }
      ),
  getQuoteItemLaborCategoryRow: ({ margin, quoteItemLaborCategory }) => {
    const fieldName = utils.getFieldNameToUseAsCostByHour(
      quoteItemLaborCategory
    );
    const hours = utils.roundBasedOnThirdDecimalDigit(
      quoteItemLaborCategory.hours || 0
    );
    const costByHours = utils.roundBasedOnThirdDecimalDigit(
      quoteItemLaborCategory.laborCategory.costByHoursAndYear[fieldName] || 0
    );
    const totalCost = utils.roundBasedOnThirdDecimalDigit(
      hours * costByHours || 0
    );
    const saleHour = utils.roundBasedOnThirdDecimalDigit(
      costByHours * (1 + margin / 100)
    );
    const totalSale = utils.roundBasedOnThirdDecimalDigit(hours * saleHour);
    return { hours, costByHours, totalCost, saleHour, totalSale };
  },
  isProd: () => process.env.REACT_APP_ENV === "production",
  isTurnedIntoJob: (job) => VALID_JOB_STATUSES.includes(job?.statusId),
  buildQueryString: (data) =>
    Object.keys(data || {})
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatCurrency,
  formatCurrencyToObjectsInArray,
  formatDecimal,
  roundBasedOnThirdDecimalDigit: (num) => {
    // 1. Multiply by 100 and take the floor (to get the integer part up to two decimals).
    let base = Math.floor(num * 100);

    // 2. Extract the third decimal digit.
    const thirdDecimalDigit = Math.floor(num * 1000) % 10;

    // 3. If the third decimal digit is >= 5, increment the base.
    if (thirdDecimalDigit >= 5) {
      base++;
    }

    // 4. Divide by 100 to restore the number with two decimal places.
    return base / 100;
  },
  formatPercent: (progress, total) => {
    const percent = total ? progress / total : 0;
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(percent);
  },
  formatWeight: (weight) =>
    new Intl.NumberFormat("en-US", { style: "unit", unit: "pound" }).format(
      weight
    ),
  formatHour: (hour) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(hour) +
    " h",
  formatDate,
  formatDatesToObjectsInArray,
  formatDateTime: (date, format = "MM/DD/YYYY, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  dateForDayPicker: (receivedDate) =>
    typeof receivedDate === "string"
      ? new Date(`${receivedDate}T00:00:00`)
      : receivedDate,
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  doCalculations: (job) => {
    const calculations = job.quoteItems.reduce(
      (accumulator, quoteItem) => {
        if (quoteItem.archived) {
          return accumulator;
        }
        const { marginAmount, totalCost, totalWeight, shopHours, officeHours, salePrice } =
          utils.doQuoteItemCalculations(quoteItem, job);

        return {
          totalWeight: accumulator.totalWeight + totalWeight,
          totalShopHours: accumulator.totalShopHours + shopHours,
          totalOfficeHours: accumulator.totalOfficeHours + officeHours,
          totalCost: accumulator.totalCost + totalCost,
          totalMarginAmount: accumulator.totalMarginAmount + marginAmount,
          totalSalePrice: accumulator.totalSalePrice + salePrice
        };
      },
      {
        totalWeight: 0,
        totalShopHours: 0,
        totalOfficeHours: 0,
        totalCost: 0,
        totalMarginAmount: 0,
        totalSalePrice: 0
      }
    );

    const totalMarginPercent = calculations.totalCost
      ? (calculations.totalMarginAmount / calculations.totalCost) * 100
      : 0;

    const totalSalePerLB = calculations.totalWeight
      ? calculations.totalSalePrice / calculations.totalWeight
      : 0;

    return {
      marginAmount: calculations.totalMarginAmount,
      marginPercent: totalMarginPercent,
      totalWeight: calculations.totalWeight,
      totalCost: calculations.totalCost,
      salePrice: calculations.totalSalePrice,
      salePerLB: totalSalePerLB,
      shopHours: calculations.totalShopHours,
      officeHours: calculations.totalOfficeHours,
    };
  },

  doQuoteItemCalculations: (quoteItem, job) => {
    const { totalWeight, materialCost } =
      quoteItem.quoteItemTypeQuoteItems.reduce(
        (acc, item) => {
          return {
            totalWeight: acc.totalWeight + item.weight,
            materialCost: acc.materialCost + item.cost,
          };
        },
        { totalWeight: 0, materialCost: 0 }
      );

    const { hours: shopHours, totalCost: shopLaborCost } =
      utils.getQuoteItemLaborCategoryTotals({
        quoteItemLaborCategories: quoteItem.quoteItemLaborCategories,
        laborCategoriesExcluded: utils.laborCategoriesExcluded,
      });

    const { hours: officeHours, totalCost: officeLaborCost } =
      utils.getQuoteItemManagementRoleTotals({
        quoteItemManagementRoles: quoteItem.quoteItemManagementRoles,
      });

    const subContractorsCost = quoteItem.quoteItemSubcontractorRoles.reduce(
      (acc, item) => acc + item.cost,
      0
    );

    const shippingHandlingCost = quoteItem.quoteItemShippingHandlings.reduce(
      (acc, item) => acc + item.cost,
      0
    );

    const weldingGrindingCost =
      totalWeight * (job?.weldingGrindingMultiplier || WG_MULTIPLIER);

    let total =
      materialCost +
      subContractorsCost +
      shippingHandlingCost +
      shopLaborCost +
      officeLaborCost;

    if (moment(job.createdAt).isBefore(RELESASE_DATE)) {
      total += weldingGrindingCost;
    }

    const totalCost = total;
    const marginAmount = totalCost * (quoteItem.margin / 100);
    let salePrice = total * (1 + quoteItem.margin / 100);
    const unitPrice = utils.roundBasedOnThirdDecimalDigit(
      salePrice / quoteItem.quantity
    );
    salePrice = unitPrice * quoteItem.quantity;
    const salePerLB = totalWeight ? salePrice / totalWeight : 0;

    return {
      weldingGrindingCost,
      marginAmount,
      totalCost,
      totalWeight,
      shopHours,
      officeHours,
      salePrice,
      salePerLB,
      unitPrice,
    };
  },
  reduceQuoteItemsLaborCategories: (quoteItems) => {
    const quoteItemsLaborCategories = quoteItems
      ?.filter((quoteItem) => !quoteItem.archived)
      ?.flatMap((quoteItem) => quoteItem.quoteItemLaborCategories);
    const mergedQuoteItemsLaborCategories = quoteItemsLaborCategories
      ? Object.values(
          quoteItemsLaborCategories?.reduce((p, c) => {
            const existingItem = p[c.laborCategoryId];
            if (existingItem) {
              p[c.laborCategoryId] = {
                ...existingItem,
                hours: existingItem.hours + c.hours,
              };
            } else {
              p[c.laborCategoryId] = c;
            }
            return p;
          }, {})
        )
      : [];
    return mergedQuoteItemsLaborCategories;
  },
  reduceQuoteItemsOfficeLabors: (quoteItems) => {
    const quoteItemsOfficeLabors = quoteItems
      ?.filter((quoteItem) => !quoteItem.archived)
      ?.flatMap((quoteItem) => quoteItem.quoteItemManagementRoles);
    const mergedQuoteItemsOfficeLabors = Object.values(
      quoteItemsOfficeLabors?.reduce((p, c) => {
        const existingItem = p[c.managementRoleId];
        if (existingItem) {
          p[c.managementRoleId] = {
            ...existingItem,
            hours: existingItem.hours + c.hours,
          };
        } else {
          p[c.managementRoleId] = c;
        }
        return p;
      }, {})
    );
    return mergedQuoteItemsOfficeLabors;
  },
  reduceQuoteItemTypeQuoteItems: (quoteItems) => {
    const quoteItemTypeQuoteItems = quoteItems
      ?.filter((quoteItem) => !quoteItem.archived)
      ?.flatMap((quoteItem) => quoteItem.quoteItemTypeQuoteItems);
    const mergedQuoteItemTypeQuoteItems = Object.values(
      quoteItemTypeQuoteItems
        ?.filter(
          (quoteItemTypeQuoteItem) =>
            quoteItemTypeQuoteItem.weight > 0 || quoteItemTypeQuoteItem.cost > 0
        )
        ?.reduce((p, c) => {
          const existingItem = p[c.quoteItemTypeId];
          if (existingItem) {
            p[c.quoteItemTypeId] = {
              ...existingItem,
              cost: existingItem.cost + c.cost,
              weight: existingItem.weight + c.weight,
              sale: existingItem.sale + c.sale,
            };
          } else {
            p[c.quoteItemTypeId] = c;
          }
          return p;
        }, {})
    );
    return mergedQuoteItemTypeQuoteItems;
  },
  reduceQuoteItemShippingHandlings: (quoteItems) => {
    const quoteItemShippingHandlings = quoteItems
      ?.filter((quoteItem) => !quoteItem.archived)
      ?.flatMap((quoteItem) => quoteItem.quoteItemShippingHandlings);
    const mergedQuoteItemShippingHandlings = Object.values(
      quoteItemShippingHandlings.reduce((p, c) => {
        const existingItem = p[c.shippingHandlingOptionId];
        if (existingItem) {
          p[c.shippingHandlingOptionId] = {
            ...existingItem,
            cost: existingItem.cost + (c.cost || 0),
          };
        } else {
          p[c.shippingHandlingOptionId] = c;
        }
        return p;
      }, {})
    );
    return mergedQuoteItemShippingHandlings;
  },
  reduceQuoteItemSubcontractorRoles: (quoteItems) => {
    const quoteItemSubcontractorRoles = quoteItems
      ?.filter((quoteItem) => !quoteItem.archived)
      ?.flatMap((quoteItem) => quoteItem.quoteItemSubcontractorRoles);
    const mergedQuoteItemSubcontractorRoles = Object.values(
      quoteItemSubcontractorRoles
        .filter(
          (quoteItemSubcontractorRole) => quoteItemSubcontractorRole.cost > 0
        )
        .reduce((p, c) => {
          if (c.subcontractorRole?.replacementRoleId) {
            c.subcontractorRoleId = c.subcontractorRole.replacementRoleId;
            c.subcontractorRole = c.subcontractorRole.replacementRole;
          }

          const existingItem = p[c.subcontractorRoleId];
          if (existingItem) {
            p[c.subcontractorRoleId] = {
              ...existingItem,
              cost: existingItem.cost + c.cost,
            };
          } else {
            p[c.subcontractorRoleId] = c;
          }
          return p;
        }, {})
    );
    return mergedQuoteItemSubcontractorRoles;
  },
  removeArrays: (data) => {
    const ret = Object.keys(data)
      .filter((key) => typeof data[key] != "object")
      .reduce((p, c) => {
        p[c] = data[c];
        return p;
      }, {});
    return ret;
  },
  isAdmin: (roleId) => {
    return ADMIN_ROLE_IDS.includes(roleId);
  },
  getManHours: (job) => {
    const { quoteItems } = job;
    const laborCategoriesManHours = quoteItems
      .filter((quoteItem) => !quoteItem.archived)
      .flatMap((quoteItem) => quoteItem.quoteItemLaborCategories)
      .reduce((p, c) => p + c.hours, 0);
    return laborCategoriesManHours.toFixed(2);
  },
  calculateEVAData: (commonData, evaOverride, job, quoteRecap) => {
    const fieldName = getFieldNameToUseAsCostByHour(job);
    const actualLaborTotal = commonData.quoteItemLaborCategories.reduce(
      (acc, laborCategory) => {
        const actualHoursOverride =
          evaOverride.laborCategories[laborCategory.id];

        const actualHours =
          actualHoursOverride || actualHoursOverride === 0
            ? actualHoursOverride
            : job.timeAttendanceLaborCodeHours
                .filter(
                  ({ laborCategoryId }) => laborCategoryId === laborCategory.id
                )
                .reduce((total, { reg, ovt }) => total + reg + ovt, 0);

        const totalCost =
          actualHours * laborCategory.costByHoursAndYear[fieldName];

        return {
          actualHours: acc.actualHours + actualHours,
          totalCost: acc.totalCost + totalCost,
        };
      },
      { actualHours: 0, totalCost: 0 }
    );

    const actualManagementTotal = commonData.quoteItemManagementRoles.reduce(
      (acc, managementRole) => {
        const actualHoursOverride =
          evaOverride.managementRoles[managementRole.id];

        const actualHours =
          actualHoursOverride || actualHoursOverride === 0
            ? actualHoursOverride
            : job.timeAttendanceLaborCodeHours
                .filter(
                  ({ managementRoleId }) =>
                    managementRoleId === managementRole.id
                )
                .reduce((total, { reg, ovt }) => total + reg + ovt, 0);

        const totalCost =
          actualHours * managementRole.costByHoursAndYear[fieldName];
        return {
          actualHours: acc.actualHours + actualHours,
          totalCost: acc.totalCost + totalCost,
        };
      },
      { actualHours: 0, totalCost: 0 }
    );

    const actualShippingHandlingTotal =
      commonData.quoteItemShippingHandlings.reduce(
        (acc, quoteItemShippingHandling) => {
          const actualHoursOverride =
            evaOverride.shippingHandlingOptions[quoteItemShippingHandling.id];

          const actualCost =
            actualHoursOverride ||
            job.teklaMaterialData
              .filter(
                ({ shippingHandlingOptionId }) =>
                  shippingHandlingOptionId === quoteItemShippingHandling.id
              )
              .reduce((total, { cost = 0 }) => total + cost, 0);

          return acc + actualCost;
        },
        0
      );

    const actualSubcontractorTotal =
      commonData.quoteItemSubcontractorRoles.reduce(
        (acc, quoteItemSubcontractorRole) => {
          const actualHoursOverride =
            evaOverride.subcontractorRoles[quoteItemSubcontractorRole.id];

          const actualCost =
            actualHoursOverride ||
            job.teklaMaterialData
              .filter(
                ({ subcontractorRoleId }) =>
                  subcontractorRoleId === quoteItemSubcontractorRole.id
              )
              .reduce((total, { cost = 0 }) => total + cost, 0);

          return acc + actualCost;
        },
        0
      );
    const materialTotal = commonData.quoteItemTypeQuoteItems.reduce(
      (acc, quoteItemTypeQuoteItem) => {
        const actualHoursOverride =
          evaOverride.quoteItemTypes[quoteItemTypeQuoteItem.id];
        const actualCost =
          actualHoursOverride ||
          job.teklaMaterialData
            .filter(
              (item) => item.quoteItemTypeId === quoteItemTypeQuoteItem.id
            )
            .reduce(
              (acc, item) => {
                return {
                  totalCost: acc.totalCost + item.cost,
                  totalWeight: acc.totalWeight + parseFloat(item.weight),
                };
              },
              { totalCost: 0, totalWeight: 0 }
            );
        return {
          totalCost:
            acc.totalCost +
            (actualCost instanceof Object
              ? actualCost.totalCost
              : actualCost || 0),
          totalWeight: acc.totalWeight + parseFloat(actualCost.weight || 0),
        };
      },
      { totalCost: 0, totalWeight: 0 }
    );

    let totalCost =
      actualShippingHandlingTotal +
      actualSubcontractorTotal +
      materialTotal.totalCost +
      actualLaborTotal.totalCost +
      actualManagementTotal.totalCost;

    if (moment(job.createdAt).isBefore(RELESASE_DATE)) {
      const weldingGrindingCost =
        quoteRecap.totalWeight * job.weldingGrindingMultiplier;

      const weldingGrindingSale =
        evaOverride.weldingGrindingOverride ||
        weldingGrindingCost * (quoteRecap.marginPercent / 100) +
          weldingGrindingCost;
      totalCost += weldingGrindingSale;
    }

    const marginAmount = quoteRecap.salePrice - totalCost;
    const salePrice = totalCost + marginAmount;

    const salePerLB =
      materialTotal.totalWeight !== 0
        ? quoteRecap.salePrice / materialTotal.totalWeight
        : 0;

    const marginPercent =
      quoteRecap.salePrice !== 0
        ? (marginAmount / quoteRecap.salePrice) * 100
        : 0;

    return {
      totalCost,
      marginAmount,
      marginPercent,
      salePerLB,
      salePrice,
    };
  },
  customizeToolbarReport: (toolbar, pivot, filename) => {
    const exportHandler = (params) => {
      params.pivotRef.current.flexmonster.exportTo(params.type, params.config);
    };

    const tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs[3].menu[1].handler = exportHandler;
      tabs[3].menu[1].args = {
        pivotRef: pivot,
        type: "html",
        config: {
          filename,
        },
      };
      tabs[3].menu[2].handler = exportHandler;
      tabs[3].menu[2].args = {
        pivotRef: pivot,
        type: "csv",
        config: {
          filename,
        },
      };
      tabs[3].menu[3].handler = exportHandler;
      tabs[3].menu[3].args = {
        pivotRef: pivot,
        type: "excel",
        config: {
          filename,
          header: "First header row\nSecond header row",
        },
      };
      tabs[3].menu[4].handler = exportHandler;
      tabs[3].menu[4].args = {
        pivotRef: pivot,
        type: "image",
        config: {
          filename,
        },
      };
      tabs[3].menu[5].handler = exportHandler;
      tabs[3].menu[5].args = {
        pivotRef: pivot,
        type: "pdf",
        config: {
          filename,
        },
      };
      return tabs;
    };
  },
  initInformationModal: {
    isOpen: false,
    title: "",
    body: "",
  },
  initConfirmationModal: {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  },
  detailingTabs: DETAILING_TABS,
  laborCategoriesExcluded: LABOR_CATEGORIES_EXCLUDED,
  getFieldNameToUseAsCostByHour,
};
