import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useAuth } from "../../../providers/authProvider";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";

const periodOptions = [
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2023",
    value: "2023",
  },
];

const AnnualSalesReport = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [periodType, setPeriodType] = useState({
    value: "2025",
    label: "2025",
  });

  const CARDS = [
    "Total Estimated Man Hour YTD",
    "Average Hours Per Week",
    "Man Hour Sold Pace",
    "Total Sales YTD",
    "Average Sales Per Week",
    "Sale Pace",
    "Capacity ST/Wk",
    "Estimated Capacity for Year",
    "Est. Capacity% to YTD",
    `${periodType.label} Sales Goal`,
    "Man Hrs Sold Goal",
    "Annual Capacity % Goal",
  ];

  useEffect(() => {
    setLoading(true);
    reportsApi
      .annualSales({
        year: periodType.value,
      })
      .then((result) => {
        setLoading(false);
        setData(result);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [authContext.currentUser, refresh, periodType.value]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Annual Sales Report</h3>
              <div className="d-flex align-items-center">
                <PeriodTypeSelector
                  periodType={periodType}
                  setPeriodType={setPeriodType}
                  options={periodOptions}
                />
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex ml-3"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
                <Button
                  className="rounded ml-3"
                  color="primary"
                  size="sm"
                  onClick={() => navigate("/reports/annual-sales-sheet")}
                >
                  See Sheet
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : (
                <>
                  <div className="d-flex flex-wrap justify-content-between w-100">
                    {CARDS.map((card) => (
                      <div className="col-12 col-md-6 col-lg-3">
                        <Card className="border">
                          <CardHeader>
                            <div className="pb-1 border-bottom text-center">
                              {card}
                            </div>
                          </CardHeader>
                          <CardBody className="pt-0 pb-3">
                            <div className="text-lg text-center font-500">
                              {data[card]}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    ))}
                  </div>
                  <ListGroup>
                    <ListGroupItem className="d-flex justify-content-center align-items-center bg-lighter font-500">
                      Top 10 Customer YTD
                    </ListGroupItem>
                    {data["Top 10 Customer YTD"]?.split("\n").map((entry) => (
                      <ListGroupItem className="d-flex justify-content-between align-items-center">
                        {entry.split(":").map((data) => (
                          <span>{data}</span>
                        ))}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default AnnualSalesReport;
