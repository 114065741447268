import React, { useMemo } from "react";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";

const QuoteTotalLaborSectionTotal = ({ quoteItemLaborCategories }) => {
  const [quoteRecapContext] = useQuoteRecap();

  const totals = useMemo(
    () =>
      utils.getQuoteItemLaborCategoryTotals({
        quoteItemLaborCategories,
        margin: quoteRecapContext.markupPercentage,
      }),
    [quoteItemLaborCategories, quoteRecapContext.markupPercentage]
  );

  return (
    <tr>
      <td className="font-weight-bold py-0" width={"35%"}>
        Total Shop Labor
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatHour(totals.hours)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalCost / totals.hours)}
        </div>
      </td>
      <td className="py-0">
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalCost)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalSale / totals.hours)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalSale)}
        </div>
      </td>
    </tr>
  );
};

export default QuoteTotalLaborSectionTotal;
