import React, { useCallback, useEffect } from "react";
import { Collapse } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import QuoteItem from "./QuoteItem";
import {
  useQuoteItems,
  ACTIONS,
} from "../../../../providers/quoteItemsProvider";
import {
  useQuoteItem,
  ACTIONS as QUOTE_ITEM_ACTIONS,
} from "../../../../providers/quoteItemProvider";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import TooltipItem from "../../../TooltipItem";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const DIRECTION_DOWN = "DIRECTION_DOWN";
const DIRECTION_UP = "DIRECTION_UP";

const QuoteItemCollapsible = ({ quoteItem, onSortQuoteItem }) => {
  const [quoteItemsContext, setQuoteItemsContext] = useQuoteItems();
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const fetchData = useCallback(async () => {
    const item = await quoteItemsApi.getQuoteItems({
      id: quoteItem.id,
    });
    setQuoteItemContext({
      action: QUOTE_ITEM_ACTIONS.ON_INIT,
      payload: { quoteItem: item },
    });
  }, [quoteItem.id, setQuoteItemContext]);

  useEffect(() => {
    if (quoteItemContext.refresh) {
      fetchData();
    }
  }, [fetchData, quoteItemContext.refresh]);

  useEffect(() => {
    setQuoteItemContext({
      action: QUOTE_ITEM_ACTIONS.ON_INIT,
      payload: { quoteItem },
    });
  }, [fetchData, quoteItem, setQuoteItemContext]);

  return (
    <div className="d-flex">
      <div className="d-flex flex-column text-muted">
        <div className="d-flex align-items-start mt-2 px-2 border rounded py-2">
          # {quoteItem.order || "Not Set"}
        </div>
        <div className="flex-grow-1"></div>
      </div>
      <div className="d-flex flex-column mt-2 mx-3 flex-grow-1">
        <div
          onClick={() => {
            setQuoteItemsContext({
              action: ACTIONS.TOGGLE_SECTION,
              payload: { section: quoteItem.id },
            });
          }}
          className="d-flex justify-content-between align-middle bg-primary border border-primary rounded text-white py-2 px-3 m-0 cursor-pointer"
        >
          <span>{quoteItem.description}</span>
          <div className="px-0 col-3 d-flex align-items-center justify-content-end">
            <FontAwesomeIcon
              icon={
                quoteItemsContext.openSections[quoteItem.id]
                  ? faChevronDown
                  : faChevronRight
              }
              fixedWidth
              className="my-1"
            />
          </div>
        </div>
        <Collapse
          isOpen={quoteItemsContext.openSections[quoteItem.id]}
          className="my-2"
        >
          <QuoteItem />
        </Collapse>
      </div>
      {isJobLocked ? null : (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-start mt-2 px-2 border rounded py-2">
            <TooltipItem
              id={`sort-up-tooltip-${quoteItem.id}`}
              title="Move item up"
            >
              <FontAwesomeIcon
                className="cursor-pointer text-primary"
                icon={faArrowAltCircleUp}
                size="lg"
                onClick={() => onSortQuoteItem(DIRECTION_UP)}
              />
            </TooltipItem>
            <TooltipItem
              id={`sort-down-tooltip-${quoteItem.id}`}
              title="Move item down"
            >
              <FontAwesomeIcon
                className="ml-2 cursor-pointer text-primary"
                icon={faArrowAltCircleDown}
                size="lg"
                onClick={() => onSortQuoteItem(DIRECTION_DOWN)}
              />
            </TooltipItem>
          </div>
          <div className="flex-grow-1"></div>
        </div>
      )}
    </div>
  );
};

export default QuoteItemCollapsible;
