import React, { useRef } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import { utils } from "../../../utils/utils";
import { useJobDetails } from "../../../providers/jobDetailsProvider";
import moment from "moment";

const QBImport = ({ invoices, quoteItems }) => {
  const [jobDetails] = useJobDetails();
  const pivot = useRef(null);
  const cleanText = (text) => {
    [" "].forEach((char) => (text = text.replaceAll(char, "")));
    return text;
  };

  const rows = Object.values(
    invoices
      .map((invoice) => {
        invoice.jobInvoiceOrderAcknowledgments.map(
          (jobInvoiceOrderAcknowledgment) => {
            jobInvoiceOrderAcknowledgment.term = invoice.term;
            jobInvoiceOrderAcknowledgment.number = invoice.invoiceNumber;
            return jobInvoiceOrderAcknowledgment;
          }
        );
        return invoice;
      })
      .flatMap((invoice) => invoice.jobInvoiceOrderAcknowledgments)
      .flatMap((jobInvoiceOrderAcknowledgment) =>
        jobInvoiceOrderAcknowledgment.orderAcknowledgment.quoteItems.map(
          (quoteItemOrderAcknowledgment) => {
            const { quoteItem } = quoteItemOrderAcknowledgment;
            const quoteItemCalculations = utils.doQuoteItemCalculations(
              quoteItem,
              jobDetails.job
            );
            const name = `${jobDetails.job.customer?.name}:${cleanText(
              jobDetails.job.jobNumber
            )}`;
            return {
              Number: jobInvoiceOrderAcknowledgment.number || "-",
              Date: moment(quoteItem.createdAt).format("MM/DD/YYYY"),
              Name: jobDetails.job.customer?.name ? name : "-",
              Terms: jobInvoiceOrderAcknowledgment?.term?.name,
              Item: "1. Sales:Sales - Fabricate in House:Fabricate In House - Each",
              Description: quoteItemOrderAcknowledgment.description,
              Quantity: quoteItem.quantity,
              Rate: utils.formatDecimal(quoteItemCalculations.unitPrice) || 0,
              Total: utils.formatDecimal(quoteItemCalculations.salePrice) || 0,
              archived: quoteItem.archived || quoteItemOrderAcknowledgment.archived,
              order:
                quoteItemOrderAcknowledgment.order ||
                quoteItems[quoteItemOrderAcknowledgment.quoteItemId],
            };
          }
        )
      )
      .filter((item) => !item.archived)
      .sort(
        (x, y) =>
          (x.order || Number.MAX_SAFE_INTEGER) -
          (y.order || Number.MAX_SAFE_INTEGER)
      )
  );

  const columns = {
    Number: {
      type: "string",
    },
    Date: {
      type: "string",
    },
    Name: {
      type: "string",
    },
    Terms: {
      type: "string",
    },
    Item: {
      type: "string",
    },
    Description: {
      type: "string",
    },
    Quantity: {
      type: "string",
    },
    Rate: {
      type: "string",
    },
    Total: {
      type: "string",
    },
  };

  const report = {
    dataSource: {
      data: [columns, ...rows],
    },
    slice: {
      rows: [
        {
          uniqueName: "Number",
        },
        {
          uniqueName: "Terms",
        },
        {
          uniqueName: "Name",
        },
        {
          uniqueName: "Date",
        },
        {
          uniqueName: "Item",
        },
        {
          uniqueName: "Description",
        },
        {
          uniqueName: "Quantity",
        },
        {
          uniqueName: "Rate",
        },
        {
          uniqueName: "Total",
        },
      ],
      expands: {
        expandAll: true,
      },
    },
    options: {
      configuratorActive: false,
      grid: {
        type: "flat",
        showTotals: "off",
        showGrandTotals: "off",
        title: "QB Import",
      },
    },
  };

  return (
    <FlexmonsterReact.Pivot
      ref={pivot}
      licenseKey={process.env.REACT_APP_FLEX_M_KEY}
      toolbar={true}
      height="100%"
      report={report}
      beforetoolbarcreated={(toolbar) =>
        utils.customizeToolbarReport(toolbar, pivot, "qb_import_report")
      }
    />
  );
};

export default QBImport;
