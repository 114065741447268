import React, { useMemo } from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const QuoteRecapManagementSection = ({ quoteItem }) => {
  const itemsWithHours = useMemo(
    () =>
      quoteItem.quoteItemManagementRoles.filter(
        (quoteItemManagementRole) => quoteItemManagementRole.hours > 0
      ),
    [quoteItem]
  );

  const totals = useMemo(
    () =>
      utils.getQuoteItemManagementRoleTotals({
        quoteItemManagementRoles: itemsWithHours,
        margin: quoteItem.margin,
      }),
    [itemsWithHours, quoteItem]
  );

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {itemsWithHours
          .sort((x, y) =>
            x.managementRole.code < y.managementRole.code ? -1 : 1
          )
          .map((quoteItemManagementRole) => {
            const { hours, costByHour, totalCost, saleByHour, totalSale } =
              utils.getQuoteItemManagementRoleRow({
                quoteItemManagementRole,
                margin: quoteItem.margin,
              });
            return (
              <tr key={quoteItemManagementRole.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {`${quoteItemManagementRole.managementRole.code} - ${quoteItemManagementRole.managementRole.name}`}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleByHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <tr>
          <td className="font-weight-bold py-0" width={"35%"}>
            Total Office Labor
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatHour(totals.hours)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalCost / totals.hours || 0)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalCost)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalSale / totals.hours || 0)}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totals.totalSale)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapManagementSection;
