import React, { useReducer, createContext, useContext } from "react";
import { utils } from "../utils/utils";

const doCalculations = (quoteItems, job) => {
  let recapTotalWeight = 0,
    recapTotalShopHours = 0,
    recapTotalOfficeHours = 0,
    recapTotalCost = 0,
    recapTotalMarginAmount = 0,
    recapTotalSalePrice = 0;

  quoteItems
    .filter((quoteItem) => !quoteItem.archived)
    .forEach((quoteItem) => {
      const { marginAmount, totalCost, totalWeight, shopHours, officeHours, salePrice } =
        utils.doQuoteItemCalculations(quoteItem, job);

      recapTotalWeight = recapTotalWeight + totalWeight;
      recapTotalShopHours = recapTotalShopHours + shopHours;
      recapTotalOfficeHours = recapTotalOfficeHours + officeHours;
      recapTotalCost = recapTotalCost + totalCost;
      recapTotalMarginAmount = recapTotalMarginAmount + marginAmount;
      recapTotalSalePrice += salePrice
    });

  const weldingGrindingCost = recapTotalWeight * job.weldingGrindingMultiplier;
  const recapTotalMarginPercent = recapTotalCost
    ? (recapTotalMarginAmount / recapTotalCost) * 100
    : 0;

  const recapTotalSalePerLB = recapTotalWeight
    ? recapTotalSalePrice / recapTotalWeight
    : 0;

  return {
    weldingGrindingCost: weldingGrindingCost,
    marginAmount: recapTotalMarginAmount,
    marginPercent: (recapTotalMarginAmount / recapTotalSalePrice) * 100,
    markupPercentage: recapTotalMarginPercent,
    totalWeight: recapTotalWeight,
    totalCost: recapTotalCost,
    salePrice: recapTotalSalePrice,
    salePerLB: recapTotalSalePerLB,
    shopHours: recapTotalShopHours,
    officeHours: recapTotalOfficeHours,
  };
};

const initialState = {
  marginAmount: 0,
  marginPercent: 0,
  totalWeight: 0,
  totalCost: 0,
  salePrice: 0,
  salePerLB: 0,
  shopHours: 0,
  officeHours: 0,
};

const ACTIONS = {
  ON_DO_CALCULATIONS: "ON_DO_CALCULATIONS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.ON_DO_CALCULATIONS:
      const { payload } = data;
      const { quoteItems, job } = payload;

      const {
        marginAmount,
        marginPercent,
        totalWeight,
        totalCost,
        salePrice,
        salePerLB,
        shopHours,
        officeHours,
        weldingGrindingCost,
        markupPercentage,
      } = doCalculations(quoteItems, job);

      return {
        ...state,
        marginAmount,
        marginPercent,
        totalWeight,
        totalCost,
        salePrice,
        salePerLB,
        shopHours,
        officeHours,
        weldingGrindingCost,
        markupPercentage,
      };

    default:
      return {
        ...initialState,
      };
  }
};

const quoteRecap = createContext(initialState);

const QuoteRecapProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <quoteRecap.Provider value={stateAndDispatch}>
      {children}
    </quoteRecap.Provider>
  );
};

export const useQuoteRecap = () => useContext(quoteRecap);

export { quoteRecap, QuoteRecapProvider, ACTIONS };
