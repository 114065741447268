import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { utils } from "../../../../../utils/utils";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";

const EstimatedActualManagementSection = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [jobDetails] = useJobDetails();
  const [quoteItemsContext] = useQuoteItems();

  const [managementRoles, setManagementRoles] = useState([]);
  const fieldName = utils.getFieldNameToUseAsCostByHour(jobDetails.job);

  useEffect(() => {
    const quoteItemManagementRoles = utils.reduceQuoteItemsOfficeLabors(
      quoteItemsContext.quoteItems
    );

    const managementRoles = commonDataContext.quoteItemManagementRoles.map(
      (managementRole) => {
        const actualHoursOverride =
          evaOverrideContext.managementRoles[managementRole.id];

        const taEntries = jobDetails.job.timeAttendanceLaborCodeHours.filter(
          (item) => item.managementRoleId === managementRole.id
        );

        const actualHours =
          actualHoursOverride || actualHoursOverride === 0
            ? actualHoursOverride
            : taEntries.reduce(
                (p, c) =>
                  utils.roundBasedOnThirdDecimalDigit(p + c.reg + c.ovt),
                0
              );

        const estimatedItem = quoteItemManagementRoles.find(
          (quoteItemLaborCategory) =>
            quoteItemLaborCategory.managementRoleId === managementRole.id
        );

        const estimatedHours = estimatedItem?.hours || 0;

        const totalCost =
          actualHours * managementRole.costByHoursAndYear[fieldName];

        let costByHours = 0;
        let costHoursTotal = 0;
        if (estimatedItem) {
          costByHours =
            estimatedItem.managementRole.costByHoursAndYear[fieldName];
          costHoursTotal = costByHours * estimatedHours;
        }

        return {
          ...managementRole,
          actualHours,
          estimatedHours,
          totalCost,
          costByHours,
          costHoursTotal,
        };
      }
    );
    setManagementRoles(managementRoles);
  }, [
    quoteItemsContext,
    commonDataContext,
    evaOverrideContext.managementRoles,
    jobDetails.job.timeAttendanceLaborCodeHours,
    fieldName,
  ]);

  const actualTotals = managementRoles.reduce(
    (p, c) => {
      return {
        actualHours: p.actualHours + c.actualHours,
        difference: p.difference + c.estimatedHours - c.actualHours,
        totalCost: p.totalCost + c.totalCost,
        totalCostEstimated: p.totalCostEstimated + c.costHoursTotal,
      };
    },
    { actualHours: 0, difference: 0, totalCost: 0, totalCostEstimated: 0 }
  );

  const estimatedHoursTotal = managementRoles.reduce(
    (p, c) => p + c.estimatedHours,
    0
  );

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {managementRoles.map((managementRole) => {
          const difference =
            managementRole.estimatedHours - managementRole.actualHours;

          return (
            <tr key={managementRole.code}>
              <td className="py-0 text-truncate" width={"40%"}>
                {`${managementRole.code} - ${managementRole.name}`}
              </td>
              <td className="py-0" width={"10%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatHour(managementRole.estimatedHours)}
                </div>
              </td>
              <td className="py-0" width={"10%"}>
                <div readOnly className="form-control border text-right m-1">
                  {managementRole.costByHours
                    ? utils.formatCurrency(managementRole.costHoursTotal)
                    : "-"}
                </div>
              </td>
              <td className="py-0" width={"10%"}>
                <Input
                  className="form-control border text-right m-1 hide-arrows"
                  type={evaOverrideContext.editEnabled ? "number" : "text"}
                  step="0.1"
                  value={
                    evaOverrideContext.editEnabled
                      ? parseFloat(managementRole.actualHours)
                      : utils.formatHour(managementRole.actualHours)
                  }
                  readOnly={!evaOverrideContext.editEnabled}
                  onChange={(evt) =>
                    setEvaOverrideContext({
                      action: EVA_OVERRIDE_ACTIONS.UPDATE_ITEM,
                      payload: {
                        id: managementRole.id,
                        key: "managementRoles",
                        value: evt.currentTarget.value,
                      },
                    })
                  }
                />
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(managementRole.totalCost)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <div
                  readOnly
                  className={`${
                    difference < 0 ? "text-danger" : "text-success"
                  } form-control border text-right m-1 font-weight-bold`}
                >
                  {utils.formatHour(difference)}
                </div>
              </td>
            </tr>
          );
        })}
        <tr className="text-muted bg-lighter">
          <th width={"40%"} className="align-middle">
            Total
          </th>
          <td className="py-0" width={"10%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatHour(estimatedHoursTotal)}
            </div>
          </td>
          <td className="py-0" width={"10%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(
                estimatedHoursTotal ? actualTotals.totalCostEstimated : 0
              )}
            </div>
          </td>
          <td className="py-0" width={"10%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatHour(actualTotals.actualHours)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualTotals.totalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`form-control border text-right m-1 font-weight-bold ${
                actualTotals.difference < 0 ? "text-danger" : "text-success"
              }`}
            >
              {utils.formatHour(actualTotals.difference)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimatedActualManagementSection;
