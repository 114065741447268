import React, { useMemo } from "react";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";

const QuoteTotalOfficeSectionTotal = ({ quoteItemManagementRoles }) => {
  const [quoteRecapContext] = useQuoteRecap();

  const totals = useMemo(
    () =>
      utils.getQuoteItemManagementRoleTotals({
        quoteItemManagementRoles,
        margin: quoteRecapContext.markupPercentage,
      }),
    [quoteItemManagementRoles, quoteRecapContext.markupPercentage]
  );

  return (
    <tr>
      <td className="font-weight-bold py-0" width={"35%"}>
        Total Office Labor
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatHour(totals.hours)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalCost / totals.hours || 0)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalCost)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalSale / totals.hours || 0)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totals.totalSale)}
        </div>
      </td>
    </tr>
  );
};

export default QuoteTotalOfficeSectionTotal;
