import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";
import QuoteTotalLaborSectionTotal from "./QuoteTotalLaborSectionTotal";

const QuoteTotalLaborSection = () => {
  const [quoteRecapContext] = useQuoteRecap();
  const [quoteItemsContext] = useQuoteItems();
  const [quoteItemLaborCategories, setQuoteItemLaborCategories] = useState([]);

  useEffect(() => {
    const quoteItemLaborCategories = utils.reduceQuoteItemsLaborCategories(
      quoteItemsContext.quoteItems
    );
    setQuoteItemLaborCategories(
      quoteItemLaborCategories.filter(
        (quoteItemLaborCategory) =>
          !utils.laborCategoriesExcluded.includes(
            +quoteItemLaborCategory.laborCategory.code
          )
      )
    );
  }, [quoteItemsContext]);

  return (
    <Table className="col-12 px-0 mt-4 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th width={"35%"} className="align-middle">
            Shop Labor Code/Cat.
          </th>
          <th width={"13%"} className="text-center">
            Est. Hours
          </th>
          <th width={"13%"} className="text-center">
            Cost/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Cost
          </th>
          <th width={"13%"} className="text-center">
            Sale/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Sale
          </th>
        </tr>
      </thead>
      <tbody>
        {quoteItemLaborCategories
          .sort((x, y) =>
            parseInt(x.laborCategory.code) < parseInt(y.laborCategory.code)
              ? -1
              : 1
          )
          .map((quoteItemLaborCategory) => {
            const { hours, costByHours, totalCost, saleHour, totalSale } =
              utils.getQuoteItemLaborCategoryRow({
                quoteItemLaborCategory,
                margin: quoteRecapContext.markupPercentage,
              });
            return (
              <tr key={quoteItemLaborCategory.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {quoteItemLaborCategory.laborCategory.code
                    ? `${quoteItemLaborCategory.laborCategory.code} - ${quoteItemLaborCategory.laborCategory.name}`
                    : quoteItemLaborCategory.laborCategory.name}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <QuoteTotalLaborSectionTotal
          quoteItemLaborCategories={quoteItemLaborCategories}
        />
      </tbody>
    </Table>
  );
};

export default QuoteTotalLaborSection;
