import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobKPIs } from "../../../providers/jobsKPIsProvider";
import SidebarMenu from "./SidebarMenu";

const SidebarItem = ({
  name,
  badgeColor,
  badgeText,
  icon,
  to,
  action,
  counting,
  entries,
  disableCollapse,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobKPIsContext] = useJobKPIs();
  let itemsToDisplay = [];

  const createSidebarItem = (
    name,
    badgeColor,
    badgeText,
    icon,
    to,
    action,
    counting,
    tabEnabled
  ) => (
    <li
      key={name}
      id={name}
      className={`sidebar-item ${location.pathname === to ? "active" : ""} ${
        tabEnabled ? "ml-3" : ""
      }`}
      onClick={() => (action ? action() : navigate(to))}
    >
      <NavLink
        to="#"
        className={({ isActive }) =>
          `sidebar-link text-truncate ${isActive ? "active" : "none"}`
        }
      >
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="align-middle mx-2 sidebar-icon"
            />
            <span className="align-middle">{name}</span>
          </React.Fragment>
        ) : (
          <span>
            {name}
            {counting ? (
              <b
                className={`${
                  jobKPIsContext[counting] <= 0 ? "bg-dark" : "bg-danger"
                } text-white m-0 ml-1 p-1 py-0`}
                style={{ fontSize: "9px", borderRadius: "45%" }}
              >
                {jobKPIsContext[counting]}
              </b>
            ) : null}
          </span>
        )}
        {badgeColor && badgeText !== undefined ? (
          <Badge
            style={{ paddingTop: "4px" }}
            color={badgeColor}
            size={18}
            className="sidebar-badge"
          >
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );

  if (entries && disableCollapse) {
    itemsToDisplay = [
      createSidebarItem(
        name,
        badgeColor,
        badgeText,
        icon,
        to,
        action,
        counting
      ),
    ];
    entries.forEach((entry) =>
      itemsToDisplay.push(
        createSidebarItem(
          entry.name,
          null,
          null,
          entry.icon,
          entry.path,
          entry.action,
          entry.counting,
          true
        )
      )
    );
  }

  return itemsToDisplay.length ? (
    itemsToDisplay
  ) : entries && !disableCollapse ? (
    <SidebarMenu
      name={name}
      icon={icon}
      to={to}
      entries={entries}
      hasParent={true}
    />
  ) : (
    createSidebarItem(name, badgeColor, badgeText, icon, to, action, counting)
  );
};

export default SidebarItem;
