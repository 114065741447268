import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useWorkOrder } from "../../../../../providers/workOrderProvider";
import { workOrderItemApi } from "../../../../../services/workOrderItemServices";

import InformationModal from "../../../../InformationModal";
import Loader from "../../../../Loader";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { utils } from "../../../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const WorkOrderItemModal = ({ defaultItem, onClose, onSubmit }) => {
  const [loading, setLoading] = useState();
  const [workOrder] = useWorkOrder();

  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);

  const [workOrderItem, setWorkOrderItem] = useState(
    defaultItem
      ? {
          ...defaultItem,
          expectedDate: defaultItem?.expectedDate
            ? moment(defaultItem?.expectedDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        }
      : {
          poNumber: "",
          vendor: "",
          description: "",
          expectedDate: null,
          receivedDate: null,
          workOrderId: null,
        }
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (workOrderItem.id) {
        await workOrderItemApi.updateWorkOrderItem(workOrderItem);
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Updated Work Order Item",
          body: "Work Order Item updated successfully.",
          onClose: () => {
            onSubmit();
            setInformationModal({ isOpen: false });
          },
        });
      } else {
        await workOrderItemApi.createWorkOrderItem({
          ...workOrderItem,
          workOrderId: workOrder.workOrder.id,
        });
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Create Work Order Item",
          body: "Work Order Item created successfully.",
          onClose: () => {
            setInformationModal({ isOpen: false });
            onSubmit();
          },
        });
      }
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Create Work Order Item",
        body: "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Create Work Order Item
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="col">
                <Row className="col-12 mb-3">
                  <Col row={6}>
                    <Label>PO#</Label>
                    <span className="text-danger ml-2">*</span>
                    <Input
                      required
                      value={workOrderItem?.poNumber || ""}
                      onChange={(e) =>
                        setWorkOrderItem({
                          ...workOrderItem,
                          poNumber: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col row={6}>
                    <Label>Vendor</Label>
                    <span className="text-danger ml-2">*</span>
                    <Input
                      required
                      value={workOrderItem?.vendor || ""}
                      onChange={(e) =>
                        setWorkOrderItem({
                          ...workOrderItem,
                          vendor: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12}>
                    <Label>Description</Label>
                    <span className="text-danger ml-2">*</span>
                    <Input
                      type="textarea"
                      required
                      value={workOrderItem?.description || ""}
                      onChange={(e) =>
                        setWorkOrderItem({
                          ...workOrderItem,
                          description: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-7 mb-0 pr-4">
                  <Col row={6}>
                    <Label>Expected Date</Label>
                    {dateSelectorOpen ? (
                      <DayPicker
                        required={true}
                        className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                        mode="single"
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        defaultMonth={
                          workOrderItem?.expectedDate
                            ? new Date(workOrderItem.expectedDate)
                            : new Date()
                        }
                        selected={
                          workOrderItem?.expectedDate
                            ? utils.dateForDayPicker(workOrderItem.expectedDate)
                            : new Date()
                        }
                        onDayClick={(expectedDate) => {
                          setDateSelectorOpen(false);
                          setWorkOrderItem({
                            ...workOrderItem,
                            expectedDate:
                              moment(expectedDate).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    ) : (
                      <div className="col-12 d-flex z-index-0 p-2 border rounded bg-white cursor-pointer justify-content-between">
                        <FontAwesomeIcon
                          size="lg"
                          icon={faCalendar}
                          className="mr-3 z-index-0 text-secondary"
                          onClick={() => setDateSelectorOpen(true)}
                        />
                        <span
                          onClick={() => setDateSelectorOpen(true)}
                          className="col-8"
                        >
                          {workOrderItem.expectedDate
                            ? utils.formatDate(workOrderItem.expectedDate)
                            : "Select a Date ..."}
                        </span>
                        <FontAwesomeIcon
                          size="lg"
                          icon={faWindowClose}
                          className="z-index-1 float-right ml-auto align-self-center text-danger rounded"
                          onClick={() =>
                            setWorkOrderItem({
                              ...workOrderItem,
                              expectedDate: null,
                            })
                          }
                          color={"#adb5bd"}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderItemModal;
